<template>
  <v-container grid-list-md px-0 mb-4 pb-4 pt-0 mt-0>
    <v-layout row mb-3 wrap fluid pt-0 mt-0>
      <v-flex
        xs12
        sm6
        md4
        lg3
        xl2
        mb-4
        pb-4
        v-for="card in members"
        :key="card.title"
        pt-0
        mt-0
      >
        <v-card
          height="100%"
          style="position: relative"
          class="flexcard"
          pb-4
          mb-4
        >
          <v-img max-height="200" height="100%" :src="card.image">
            <v-card-title class="title event-title-bottom justify-center py-1">
              {{ card.headline }}
            </v-card-title>
            <div class="contact-button">
              <v-spacer />
              <v-btn
                icon
                large
                right
                :href="card.address"
                target="_blank"
                class="primary"
              >
                <v-icon>mdi-email-plus</v-icon>
              </v-btn>
            </div>
          </v-img>
          <v-card-text class="title primary-title pb-0 text-xs-center">
            {{ card.title }}
          </v-card-text>
          <v-card-text class="grey--text text-xs-center text-sm-left mb-4 caption">
            <div
              v-html="card.desc"              
            />            
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>  
</template>

<script>
import { db } from "@/fb";
export default {
  metaInfo: {
    titleTemplate: "%s | The team",
    link: [
      { rel: "canonical", href: "https://www.manchesterleathermen.com/team" },
    ],
    meta: [
      { itemprop: "name", content: "The Team ← Manchester Leathermen " },
      {
        itemprop: "description",
        content: "Meet the team and get in touch with us.",
      },
    ],
  },
  created() {
    // fetch data from the db
    db.collection("ourteam")
    .get()
    .then((snapshot) => {
      snapshot.forEach((doc) => {
        let member = doc.data();
        member.id = doc.id;
        this.members.push(member);
      })
    })
  },
  data: () => ({
    members: [],
  }),
};
</script>

<style>
.flexcard {
  display: flex;
  flex-direction: column;
}
</style>
